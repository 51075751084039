'use client';

import HomeViewPaper from '@/src/components/HomeViewPaper';
import NatomaDescope from '@/src/components/NatomaDescope';
import { useRouter } from 'next/navigation';
import useEnvironment from '../../../providers/Environment';

export default function TenantLoginPage() {
  const router = useRouter();
  const { natoma } = useEnvironment();
  const tenant = window.location.hostname.replace(`.${natoma.rootDomain}`, '');

  const handleSuccess = () => {
    router.push('/');
  };
  return (
    <HomeViewPaper>
      <NatomaDescope
        flowId="sign-in"
        tenant={tenant}
        onSuccess={handleSuccess}
      />
    </HomeViewPaper>
  );
}
